.main__about {
  margin-top: 6rem;
  width: 100%;
  text-align: center;

  h3 {
    font-size: 2.25rem;
    margin-bottom: 5rem;

    &:after {
      transition-duration: 300ms;
      margin-left: auto;
      margin-right: auto;
      content: "";
      display: block;
      height: 3px;
      margin-top: 0.3rem;
      left: auto;
      background: $primary-color;
      width: 3.6em;
    }
  }

  p {
    width: 75%;
    font-size: 1.1rem;
    line-height: 1.35rem;
    margin-right: auto;
    margin-left: auto;
    text-align: justify;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .main__about {
    h3 {
      font-size: 3rem;
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .main__about {
    margin-top: 6rem;

    h3 {
      font-size: 3.5rem;
    }

    p {
      width: 50rem;
    }
  }
}

@media (min-width: 1200px) {
  .main__about {
    h3 {
      font-size: 3.5rem;
    }

    p {
      width: 60rem;
    }
  }
}