/* import fonts */
@import "fonts";

/* Set default font family */
body {
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}

/* import global level variables */
@import "variables";
/* import style for header */
@import "header";
/* import style for navigation */
@import "navigation";
/* import style for about section */
@import "about";
/* import style for course section */
@import "course";
/* import style for price list and contact form section */
@import "price_list_contact";
/* import style for footer */
@import "footer";

/* width */
::-webkit-scrollbar {
  width: 0.75rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: $bg-color;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #757575;
}

::selection {
  background-color: #757575;
  color: white;
}