.main__price-list-section {
  display: flex;
  margin-top: 12rem;
  flex-direction: column;
  justify-content: space-between;

  article {
    width: 100%;

    h3 {
      font-size: 3em;
      margin-bottom: 6rem;
    }
  }

  .main__price-list {
    text-align: center;
    margin-bottom: 9.375rem;

    img {
      margin-top: 6.25rem;
      width: 37.5rem;
      display: none;
    }

    ul {
      transform: translateX(-15px);
      list-style: none;

      font-size: 1.4em;
    }

    h3::after {

      margin-left: auto;
      margin-right: auto;
      content: "";
      display: block;
      height: 5px;
      margin-top: 3px;
      left: auto;
      background: $primary-color;
      max-width: 4em;

    }
  }

  .main__contac {
    h3 {
      text-align: center;
      &::after {
        margin-left: auto;
        margin-right: auto;
        content: "";
        display: block;
        height: 5px;
        margin-top: 3px;
        left: auto;
        background: $primary-color;
        max-width: 5.5em;

      }
    }

    form {

      margin-left: auto;
      margin-right: auto;
      width: 70%;
      max-width: 31.25rem;
      text-align: left;

      input {
        height: 2.5rem;
        border-radius: 10px;
        padding: 5px;
        border: 2px solid #949494;
        width: 100%;

        &:focus {
          outline: none;
          border: 2px solid $primary-color;
        }
      }

      textarea {
        margin-bottom: 0.9375rem;
        width: 100%;
        border-radius: 10px;
        border: 2px solid #949494;
        height: 15.625rem;
        padding: 5px;
        resize: none;

        &:focus {
          outline: none;
          border: 2px solid $primary-color;
        }
      }

      label {
        font-weight: bold;
        font-size: 1.1em;
        display: block;
        padding: 20px 0 15px 0;
      }

      button {
        margin-top: 1.25rem;
        width: 7.5rem;
        height: 2.1875rem;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        border-radius: 10px;
        padding: 10px;
        font-weight: bold;
        color: white;
        background-color: $primary-color;
        border: none;
        transition-duration: 300ms;

        &:hover {
          cursor: pointer;
          background-color: #dd710c;
        }
      }
    }
  }
}

@media (min-width: 1050px) {
  .main__price-list-section {
    flex-direction: row;

    .main__price-list {
      img {
        display: block;
      }
      h3::after {

        content: "";
        display: block;
        height: 5px;
        margin-top: 3px;
        left: auto;
        background: $primary-color;
        max-width: 4em;
      }
    }
  }
}
