.header {
  background-image: url("../img/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 4rem);
  text-align: center;
}
a {
  color: black;
  text-decoration: none;
}
.header__content {
  position: relative;
  top: 45%;
  transform: translateY(-50%);

  h1 {
    margin: 0;
  }

  h2 {
    font-weight: normal;
    color: $primary-color;
    font-size: 1.2rem;
  }
}

.header__image {
  display: none;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .header__content {
    h1 {
      font-size: 3.5rem;
    }

    h2 {
      font-size: 2rem;
    }
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .header {
    clip-path: polygon(100% 0, 100% 95%, 50% 100%, 0 95%, 0 0);
    height: 95vh;
  }
  .header__content {
    h1 {
      font-size: 4rem;
    }

    h2 {
      font-size: 2.5rem;
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .header {
    display: flex;
    text-align: left;
    align-items: center;
  }
  .header__content {
    width: 100%;
    top: initial;
    transform: none;
    margin-left: 5%;

    h1 {
      font-size: 4rem;
    }

    h2 {
      font-size: 2rem;
    }
  }

  .header__image {
    display: block;
    text-align: right;
    margin-right: 5%;

    img {
      width: 25rem;
    }
  }


}


/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .header__content {
    h1 {
      font-size: 5rem;
    }

    h2 {
      font-size: 2.67rem;
    }
  }
  .header__image {
    img {
      width: 30rem;
    }
  }
}

@media (min-width: 1576px) {
  .header__content {
    margin-left: 10%;

    h1 {
      font-size: 6rem;
    }

    h2 {
      font-size: 3rem;
    }
  }
  .header__image {
    margin-right: 10%;

    img {
      width: 32rem;
    }
  }
}