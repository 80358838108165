.navbar {
  display: flex;
  justify-content: space-between;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 4rem;
}

.navbar__logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation {
  padding-top: 10vh;
  border-right: 1px solid #b0b0b0;
  z-index: 2;
  position: fixed;
  left: -100%;
  transition-property: left;
  transition-duration: 200ms;
  background-color: #FFF;
  height: 100%;
  width: 40vw;

  ul {
    list-style: none;
    padding: 0;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}

.navigation--active {
  tab-index: -1;
  left: 0;
}

.navigation__item {
  margin: 5px 0 5px 0;
  height: 20px;
  transform: translateY(3px);

  a {
    color: black;
    text-decoration: none;

    &:hover, &:focus {
      color: $primary-color;
    }

    &::after {
      margin-left: auto;
      margin-right: auto;
      content: "";
      display: block;
      height: 2px;
      margin-top: 3px;
      left: auto;
      position: absolute;
      background: $primary-color;
      width: 0;
    }
  }

  padding: 5px;
}

.hamburger {
  border: none;
  background-color: white;
  position: relative;

  img {
    display: block;
    width: .875em;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -.125em;
  }
}

@media (min-width: 936px) {

  .navbar {
    transition-duration: 400ms;
    height: initial;
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 2;
    padding: 0;
    margin: 0;
    top: 0;

    .navbar__logo {
      padding-left: 3rem;
    }

    .navigation {
      background-color: initial;
      padding-right: 3rem;
    }
  }
  .hamburger {
    display: none;
  }
  .navigation {
    position: static;
    padding-top: 0;
    border-right: none;

    ul {
      margin-left: auto;
      margin-right: 0;
    }
  }
  .navigation__item {
    display: inline-block;
    padding: 0 20px 0 20px;

    &:last-child {
      padding-right: 0;
    }
  }
  .navbar--transparent {
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
