/* Regular */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(../font/Montserrat/Montserrat-Regular.ttf) format('truetype');
}

/* Medium */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url(../font/Montserrat/Montserrat-Medium.ttf) format('truetype');
}

/* Bold */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(../font/Montserrat/Montserrat-Bold.ttf) format('truetype');
}