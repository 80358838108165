footer {
    background-color: #F4F4F4;
}

.footer {
    display: flex;
    background-color: #F4F4F4;
    margin-top: 9.375rem;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;

    h5 {
        font-size: 1.5em;
        line-height: 5px;
        margin: 3.125rem 0 1.5625rem 10%;

        &:first-child {
            font-size: 2em;
            line-height: 15px;
            text-align: center;
            margin: 50px 0 50px 0;
        }
    }

    h6 {
        text-align: center;
        font-size: 1.25em;
        font-weight: normal;
        line-height: 0;
        position: relative;
        top: -0.9375rem;
    }

    p {
        max-width: 100%;
        text-align: justify;
        margin-left: 10%;
        margin-right: 10%;
    }

    .footer__map {
        text-align: center;
        width: 100%;
        height: 25rem;
        margin-bottom: 3.125rem;
        margin-top: 3.125rem;

        iframe {
            width: 90%;
            height: 25rem;
        }
    }
}

.footer__copyright {
    width: 100%;
    background-color: #2D2C2C;
    height: 6.25rem;
    bottom: 0;
    text-align: center;
    display: flex;

    p {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translateY(-90%) translateX(-50%);
        color: white;
        height: fit-content;
        font-size: 0.85em;
    }

    .accent {
        color: $primary-color;
    }
}

.contact-item {
    line-height: 1.2rem;
}

@mixin social-item {
    font-size: 1rem;
    text-align: center;
    padding: 0.25rem;
    margin-right: 10px;
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid black;
    border-radius: 50%;
    display: inline-block;
    overflow: visible;
    vertical-align: -.125em;
}

.contact-icon {
    display: inline-block;
    width: .875em;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -.125em;
}

.social {
    text-align: center;
    margin-top: 4.2rem;

    .social-facebook {
        @include social-item;

        &:hover, &:focus {
            color: #3b5999;
            border-color: #3b5999;
            cursor: pointer;
        }
    }

    .social-instagram {
        @include social-item;

        &:hover, &:focus {
            color: #e4405f;
            border-color: #e4405f;
            cursor: pointer;
        }
    }

    .social-youtube {
        @include social-item;

        &:hover, &:focus {
            color: #cd201f;
            border-color: #cd201f;
            cursor: pointer;
        }
    }

    .social-linkedin {
        @include social-item;

        &:hover, &:focus {
            color: #0077B5;
            border-color: #0077B5;
            cursor: pointer;
        }
    }

    .social-twitter {
        @include social-item;

        &:hover, &:focus {
            color: #55acee;
            border-color: #55acee;
            cursor: pointer;
        }
    }
}

@media (min-width: 1090px) {
    .social {
        text-align: left;
        margin-bottom: 3rem;
    }

    .footer {
        flex-direction: row;
        text-align: left;

        h5 {
            margin: 50px 0 25px 0;
        }

        h5:first-child {
            text-align: left;
        }

        h6 {
            text-align: left;
        }

        p {
            margin-left: initial;
            width: 23rem;
            margin-right: initial;
        }

        .footer__map {
            width: initial;
            height: initial;
            margin-top: initial;
            margin-bottom: initial;

            iframe {
                width: 600px;
                height: 500px;
                margin: 100px 50px 150px 0;
            }
        }
    }

    .footer__content {
        padding-left: 50px;
        padding-top: 80px;
    }
}

@media (min-width: 1370px) {
    .footer {
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;
        p{
          width: 30rem;
        }
    }
}
