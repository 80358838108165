.main__course {
  text-align: center;

  .main__course__card-wrapper {
    display: flex;
    flex-direction: column;
  }

  h3 {
    font-size: 2.25rem;
    margin-top: 6rem;

    &:after {
      transition-duration: 300ms;
      margin-left: auto;
      margin-right: auto;
      content: "";
      display: block;
      height: 3px;
      margin-top: 0.3rem;
      left: auto;
      background: $primary-color;
      width: 3.6em;
    }
  }

  .course__card {
    max-width: 17.9rem;
    width: 75%;
    min-height: 405px;
    border: 1px solid black;
    border-radius: 20px;
    padding: 1.2rem;
    margin: 4rem auto 0 auto;

    h4 {
      font-weight: bold;
      font-size: 1.2rem;
    }

    p {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
      font-size: .9rem;
      line-height: 1.15rem;
    }

    img {
      height: 10.625rem;
    }
  }
}

@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 820px) {
  .main__course {
    margin-top: 10rem;

    .main__course__card-wrapper {
      flex-direction: row;
      justify-content: space-between;
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
    }

    .course__card {
      margin-left: 1.2rem;
      margin-right: 1.2rem;
    }

    h3 {
      font-size: 3rem;
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .main__course {
    h3 {
      font-size: 3.5rem;
    }
  }
}

@media (min-width: 1200px) {
  .main__course {
    h3 {
      font-size: 3.5rem;
    }
  }
}